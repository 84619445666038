'use client';
import { type Team } from '.prisma/client';
import { type User } from '@/app/_types/account';
import { type SystemNotification } from '@/app/_types/notifications';
import { NotificationTypeEnum } from '@/app/_utils/constants/Notification';
import { ACCOUNT_TYPE, USER_ROLE } from '@/app/_utils/constants/User';
import {
  AccountActionTypes,
  accountReducer,
  type AccountActions,
  type AccountState,
} from '@/app/_utils/context/Account/AccountReducer';
import { notificationDataParser } from '@/app/_utils/helpers/data/notificationDataParser';
import { type Invitation, type Notification } from '@prisma/client';
import { getSession } from 'next-auth/react';
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  type Dispatch,
  type ReactNode,
} from 'react';
import { useLocalStorage } from 'usehooks-ts';

interface AccountProviderProps {
  refetchInterval?: number;
  children: ReactNode;
}

export const initialState: AccountState = {
  user: null,
  selectedAccount: {
    type: ACCOUNT_TYPE.USER,
    name: '',
    role: USER_ROLE.USER,
    email: '',
    id: '',
  },
  notifications: [],
  notificationCount: 0,
  isLoading: false,
  isError: false,
};

const AccountContext = createContext<{
  state: AccountState;
  dispatch: Dispatch<AccountActions>;
  refetchData: () => Promise<void>;
  refetchNotification: () => Promise<void>;
}>({
  state: initialState,
  dispatch: () => null,
  refetchData: () => new Promise(() => null),
  refetchNotification: () => new Promise(() => null),
});

export const AccountProvider = ({
  refetchInterval = 1 * 60,
  children,
}: AccountProviderProps) => {
  const [initialLocalState, setState] = useLocalStorage<Partial<AccountState>>(
    'account',
    initialState,
  );
  const [state, dispatch] = useReducer(accountReducer, {
    ...initialState,
    ...initialLocalState,
  });

  const getAccountData = async (isInitial = false) => {
    try {
      const session = await getSession();
      if (session == null) {
        return;
      }
      if (isInitial) {
        dispatch({
          type: AccountActionTypes.SET_LOADING,
          payload: {
            isLoading: true,
          },
        });
      }

      const currUserRoleResponse = await fetch('/api/trpc/user.getCurrUser');
      const {
        result: {
          data: { json },
        },
      } = (await currUserRoleResponse.json()) as {
        result: { data: { json: User } };
      };

      dispatch({
        type: AccountActionTypes.SET_USER,
        payload: {
          ...json,
          isManager:
            json.role === USER_ROLE.MANAGER || json.role === USER_ROLE.ADMIN,
          isAdmin: json.role === USER_ROLE.ADMIN,
          isBeta:
            json.hasBetaAccess ||
            json.role === USER_ROLE.MANAGER ||
            json.role === USER_ROLE.ADMIN,
        },
      });

      if (
        state.selectedAccount &&
        state.selectedAccount.type === ACCOUNT_TYPE.TEAM
      ) {
        const selectedTeam = json.teams?.find(
          (team) => team.id === state.selectedAccount?.id,
        );
        if (!selectedTeam) {
          dispatch({
            type: AccountActionTypes.SET_SELECTED_ACCOUNT,
            payload: {
              type: ACCOUNT_TYPE.USER,
              id: json.id,
            },
          });
        } else {
          dispatch({
            type: AccountActionTypes.SET_SELECTED_ACCOUNT,
            payload: {
              type: ACCOUNT_TYPE.TEAM,
              id: selectedTeam.id,
            },
          });
        }
      } else {
        dispatch({
          type: AccountActionTypes.SET_SELECTED_ACCOUNT,
          payload: {
            type: ACCOUNT_TYPE.USER,
            id: json.id,
          },
        });
      }

      dispatch({
        type: AccountActionTypes.SET_LOADING,
        payload: {
          isLoading: false,
        },
      });
    } catch (e) {
      dispatch({
        type: AccountActionTypes.SET_ERROR,
        payload: {
          isError: true,
          errorMessage: 'Error retrieving user data',
        },
      });
      console.error('Error retrieving user data', e);
    }
  };

  const getNotifications = async () => {
    try {
      const [currUserRoleResponse, notificationsResponse] = await Promise.all([
        fetch('/api/trpc/invitation.getMyInvitations'),
        fetch('/api/trpc/notification.getMyNotifications'),
      ]);
      const {
        result: {
          data: { json: invitations },
        },
      } = (await currUserRoleResponse.json()) as {
        result: { data: { json: (Invitation & { team: Team })[] } };
      };
      const invitationNotifications = invitations
        .filter((el) => el.teamId != null)
        .map<SystemNotification>((el) => ({
          id: el.id,
          title: `${el.team.name} invited you to join the team!`,
          icon: notificationDataParser(NotificationTypeEnum.INVITATION),
          type: NotificationTypeEnum.INVITATION,
          link: null,
          data: null,
          actions: [
            {
              icon: 'x-icon',
              call: '/api/trpc/invitation.rejectInvitation',
              data: {
                invitationId: el.id,
              },
            },
            {
              icon: 'check-icon',
              call: '/api/trpc/invitation.acceptInvitation',
              data: {
                invitationId: el.id,
              },
            },
          ],
        }));
      const {
        result: {
          data: {
            json: { totalCount, notifications },
          },
        },
      } = (await notificationsResponse.json()) as {
        result: {
          data: {
            json: {
              totalCount: number;
              notifications: (Notification & { type: NotificationTypeEnum })[];
            };
          };
        };
      };
      const systemNotifications = notifications.map<SystemNotification>(
        (el) => ({
          id: el.id,
          title: el.title,
          icon: notificationDataParser(el.type),
          type: el.type,
          link: el.link,
          data: el.data,
          message: el.message,
        }),
      );
      dispatch({
        type: AccountActionTypes.SET_NOTIFICATIONS,
        payload: [...invitationNotifications, ...systemNotifications],
      });
      dispatch({
        type: AccountActionTypes.SET_NOTIFICATION_COUNT,
        payload: invitationNotifications.length + totalCount,
      });
    } catch (e) {
      console.error('Error retrieving user notifications', e);
    }
  };

  useEffect(() => {
    void getAccountData(true);
  }, []);

  useEffect(() => {
    if (state.user?.id) {
      void getNotifications();
      const interval = setInterval(() => void getNotifications(), 10000);

      return () => clearInterval(interval);
    }
  }, [state.user]);

  useEffect(() => {
    setState({
      selectedAccount: state.selectedAccount,
    });
  }, [state.selectedAccount]);

  return (
    <AccountContext.Provider
      value={{
        state,
        dispatch,
        refetchData: getAccountData,
        refetchNotification: getNotifications,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount must be used within a AccountProvider');
  }
  return context;
};
