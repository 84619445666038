import { NotificationTypeEnum } from '@/app/_utils/constants/Notification';

export function notificationDataParser(
  notificationType: NotificationTypeEnum,
): string {
  switch (notificationType) {
    case NotificationTypeEnum.INVITATION:
      return 'user-plus-icon';
    case NotificationTypeEnum.DEPLOYMENT:
      return 'hard-drive-icon';
    case NotificationTypeEnum.MESSAGE:
      return 'send-icon';
    case NotificationTypeEnum.TRANSACTION:
      return 'dollar-icon';
    default:
      return 'info-icon';
  }
}

export function getDefaultNotificationLink(
  notificationType: NotificationTypeEnum,
): string | undefined {
  switch (notificationType) {
    case NotificationTypeEnum.DEPLOYMENT:
      return '/dashboard/instances';
    case NotificationTypeEnum.TRANSACTION:
      return '/dashboard/billing';
    default:
      return undefined;
  }
}
