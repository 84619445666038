export enum USER_ROLE {
  USER = 'USER',
  MANAGER = 'MANAGER',
  ADMIN = 'ADMIN',
}

export enum ACCOUNT_TYPE {
  USER = 'USER',
  TEAM = 'TEAM',
}
