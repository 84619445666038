import { type TeamRole, type UserRole } from '.prisma/client';
import { type User } from '@/app/_types/account';
import { type SystemNotification } from '@/app/_types/notifications';
import type { ActionMap } from '@/app/_types/utilTypes';
import { ACCOUNT_TYPE } from '@/app/_utils/constants/User';

export type LoggedUser = User & {
  isManager: boolean;
  isAdmin: boolean;
  isBeta: boolean;
};

export type SelectedAccount = {
  type: ACCOUNT_TYPE;
  name: string;
  email: string;
  id: string;
  image?: string;
  role: TeamRole | UserRole;
};

export type AccountState = {
  user: LoggedUser | null;
  selectedAccount: SelectedAccount;
  notifications: SystemNotification[];
  notificationCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage?: string;
};

export enum AccountActionTypes {
  SET_USER = 'SET_USER',
  SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
}

type AccountPayload = {
  [AccountActionTypes.SET_USER]: LoggedUser;
  [AccountActionTypes.SET_SELECTED_ACCOUNT]: {
    id: string;
    type: ACCOUNT_TYPE;
  };
  [AccountActionTypes.SET_NOTIFICATIONS]: SystemNotification[];
  [AccountActionTypes.SET_NOTIFICATION_COUNT]: number;
  [AccountActionTypes.REMOVE_NOTIFICATION]: string;
  [AccountActionTypes.SET_LOADING]: {
    isLoading: boolean;
  };
  [AccountActionTypes.SET_ERROR]: {
    isError: boolean;
    errorMessage?: string;
  };
  [AccountActionTypes.SET_INITIAL_STATE]: AccountState;
};

export type AccountActions =
  ActionMap<AccountPayload>[keyof ActionMap<AccountPayload>];

export const accountReducer = (
  state: AccountState,
  action: AccountActions,
): AccountState => {
  switch (action.type) {
    case AccountActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case AccountActionTypes.SET_SELECTED_ACCOUNT:
      const selectedAccount =
        action.payload.type === ACCOUNT_TYPE.USER
          ? state.user
          : state.user?.teams?.find((team) => team.id === action.payload.id);

      if (selectedAccount) {
        const newData = {
          type: action.payload.type,
          name: selectedAccount.name,
          email: selectedAccount.email,
          id: selectedAccount.id,
          image: (action.payload.type === ACCOUNT_TYPE.USER
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              selectedAccount.image
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              selectedAccount.logo) as string,
          role: selectedAccount.role,
        };
        return {
          ...state,
          selectedAccount: newData,
        };
      }

      return state;

    case AccountActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...action.payload],
      };
    case AccountActionTypes.SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload,
      };

    case AccountActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (el) => el.id !== action.payload,
        ),
      };
    case AccountActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case AccountActionTypes.SET_INITIAL_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case AccountActionTypes.SET_ERROR:
      return {
        ...state,
        isError: action.payload.isError,
        errorMessage: action.payload.isError
          ? action.payload.errorMessage
          : undefined,
      };
    default:
      return state;
  }
};
